import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import ContactInfo from '../ContactInfo/ContactInfo'
import ContactForm from '../ContactForm/ContactForm'
import '../../styles/Generic.scss'
// import './ContactSecondPart.scss'

class ContactSecondPart extends Component {

    render() {
        return (
            <Container maxWidth="lg">
                <Grid container spacing={0}>
                    <Grid item lg={6}>
                        <ContactInfo />
                    </Grid>
                    <Grid item lg={6}>
                        <ContactForm />
                    </Grid>
                </Grid>
            </Container >
        );
    }
}

export default ContactSecondPart;