import React, { Component } from 'react';
import FailModal from '../FailModal/FailModal';
import { FormControl } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import SuccessModal from '../SuccessModal/SuccessModal';
import Box from '@material-ui/core/Box';
import './ContactForm.scss';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      emailValue: '',
      message: '',

      check_name: '',
      check_email: '',
      fail_result: false,
      success_result: false,
    };
  }

  handleChangeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleChangeEmail = (e) => {
    this.setState({
      emailValue: e.target.value,
    });
  };

  handleChangeMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };


  handleClick = () => {
    const isValidName = /^[a-zA-Z]{2,20}$/.test(this.state.name);
    const isValidMail = /^[a-zA-Z][a-zA-Z0-9.-_]{1,19}@[a-z]{1,10}\.[a-z]{1,10}/.test(this.state.emailValue);

    if (isValidName === true && isValidMail === true) {

      try {

        var body = {
          name: this.state.name,
          email: this.state.emailValue,
          message: this.state.message,
        };

        fetch('https://divilabs-server.herokuapp.com/api/ema_consultancy/contact', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        this.setState({
          check_name: '',
          check_email: '',
          success_result: true,
          fail_result: false,
        });
      } catch (err) {
        throw err;
      }
    } else if (isValidName === false && isValidMail === true) {
      this.setState({
        check_name: this.props.t('valid_name'),
        check_email: '',
        fail_result: true,
        success_result: false,
      });
    } else if (isValidName === true && isValidMail === false) {
      this.setState({
        check_name: '',
        check_email: this.props.t('valid_mail'),
        fail_result: true,
        success_result: false,
      });
    } else if (isValidName === false && isValidMail === false) {
      this.setState({
        check_name: this.props.t('valid_name'),
        check_email: this.props.t('valid_mail'),
        fail_result: true,
        success_result: false,
      });
    }
  };

  formPreventDefault(e) {
    e.preventDefault();
  }

  resetFailCaseValue = (x) => {
    this.setState({
      fail_result: x,
    });
  };

  resetSuccessCaseValue = (x) => {
    this.setState({
      success_result: x,
    });
  };

  render() {
    const { emailValue, name } = this.state;

    return (
      <React.Fragment>
        <div className="text-container">{this.props.t('contact_form')}</div>
        <FormControl>
          <input className="input" placeholder={this.props.t('input_name')} value={name} onChange={this.handleChangeName} />
          <Box color="error.main">{this.state.check_name}</Box>
          <input className="input" placeholder={this.props.t('input_mail')} id="email" value={emailValue} onChange={this.handleChangeEmail} />
          <Box color="error.main">{this.state.check_email}</Box>
          <textarea className="text-area" placeholder={this.props.t('text_area')} onChange={this.handleChangeMessage} />

          <button className="check-button" onClick={this.handleClick}>
            {this.props.t('send_button')}
          </button>
        </FormControl>

        {this.state.fail_result && <FailModal resetFailCase={this.resetFailCaseValue} />}

        {this.state.success_result && <SuccessModal resetSuccessCase={this.resetSuccessCaseValue} />}
      </React.Fragment>
    );
  }
}

export default withTranslation()(ContactForm);
