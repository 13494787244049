import React, { Component } from 'react'
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';
import './FailModal.scss'

class FailModal extends Component {

    hanndleClick = () => {
        let x = false;
        this.props.resetFailCase(x)
    }

    render() {
        return (
            <div className='failModalContainer'>
                <div className="form-header-container">
                    <div className='close-btn'>
                        <ClearIcon
                            style={{ fontSize: '20px', color: "#b94a48" }}
                            onClick={this.hanndleClick}
                        />
                    </div>
                </div>
                <ReportProblemRoundedIcon
                    style={{ color: '#b94a48' }}
                />
                <div className="modal_text_part" >{this.props.t('fail_modal')}</div>
            </div>
        )
    }
}

export default withTranslation()(FailModal)
