import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';

import './App.css';

const NoMatchPage = () => {
  return <h3>404 - Not found</h3>;
};

const routes = [
  {
    path: '/',
    exact: true,
    private: false,
    component: Home,
  },
  {
    private: false,
    component: NoMatchPage,
  },
];

class App extends Component {
  async componentDidMount() {
    document.title = '';

  }

  render() {
    return (
      <div className="wrapper">
          <BrowserRouter>
            <Switch>
              {routes.map((route, index) =>
                <Route key={index} path={route.path} exact={route.exact} component={route.component} />
              )}
            </Switch>
          </BrowserRouter>
      </div>
    );
  }
}

export default App;
