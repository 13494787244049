import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Images from '../../constants/Images';

import './About.scss';
import '../../styles/Generic.scss';

class About extends Component {
  render() {
    return (
      <>
        <div id="about" className="about">
          <Container maxWidth="lg">
            <h1>{this.props.t('full_stack_expert_dev')}</h1>
            <h2>{this.props.t('about_title')}</h2>
            <h3>{this.props.t('about_sub_title')}</h3>
            <Grid container spacing={4}>
              <Grid className="about-text-container" item xs={9}>
                <div>
                  <p>{this.props.t('about_text_1')}</p>
                  <p>{this.props.t('about_text_2')}</p>
                  <p>{this.props.t('about_text_3')}</p>
                  <p>{this.props.t('about_text_4')}</p>
                  <p>{this.props.t('about_text_5')}</p>
                </div>
              </Grid>
              <div className="about-img-container">
                <div>
                  <img src={Images.profile} alt="Romeo Geworkan" />
                </div>
                <div className="about-img-text-container">
                  <p>{this.props.t('about_img_text_1')}</p>
                  <p>{this.props.t('about_img_text_2')}</p>
                </div>
              </div>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

export default withTranslation()(About);
