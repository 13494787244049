import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@material-ui/icons/Close';
import { faTimes, faCogs, faCloud, faDatabase, faEye, faCubes, faUsers } from '@fortawesome/free-solid-svg-icons';
import ExpertiseItem from '../../components/ExpertiseItem/ExpertiseItem';

import '../../styles/Generic.scss';
import './Expertise.scss';

const times = <FontAwesomeIcon icon={faTimes} className="times" alt="sap developer" />;
const cogs = <FontAwesomeIcon icon={faCogs} style={{ width: '75px', height: '75px' }} className="fa" alt="sap developer" />;
const cloud = <FontAwesomeIcon icon={faCloud} style={{ width: '75px', height: '75px' }} className="fa" alt="sap integration" />;
const eye = <FontAwesomeIcon icon={faEye} style={{ width: '75px', height: '75px' }} className="fa" alt="SAPUI5" />;
const cubes = <FontAwesomeIcon icon={faCubes} style={{ width: '75px', height: '75px' }} className="fa" alt="sap solution architecture" />;
const database = <FontAwesomeIcon icon={faDatabase} style={{ width: '75px', height: '75px' }} className="fa" alt="sap hana" />;
const users = <FontAwesomeIcon icon={faUsers} style={{ width: '75px', height: '75px' }} className="fa" alt="scrum developer master" />;

const cogsTitle = i18n.t('cogs_title');
const cogsHeader = i18n.t('cogs_header');
const cogsArr = [
  i18n.t('cogs_array_first_el'),
  i18n.t('cogs_array_second_el'),
  i18n.t('cogs_array_third_el'),
  i18n.t('cogs_array_fourth_el'),
  i18n.t('cogs_array_fifth_el'),
  i18n.t('cogs_array_sixth_el'),
  i18n.t('cogs_array_seventh_el'),
  i18n.t('cogs_array_eight_el'),
  i18n.t('cogs_array_ninth_el'),
];

const cogsBody = cogsArr.map((cogsItem) => {
  return <li>{cogsItem}</li>;
});

const cloudTitle = i18n.t('cloud_title');
const cloudHeader = i18n.t('cloud_header');
const cloudsArr = [
  i18n.t('cloud_array_first_el'),
  i18n.t('cloud_array_second_el'),
  i18n.t('cloud_array_third_el'),
  i18n.t('cloud_array_fourth_el'),
  i18n.t('cloud_array_fifth_el'),
];

const cloudBody = cloudsArr.map((cloudItem) => {
  return <li>{cloudItem}</li>;
});

const eyeTitle = i18n.t('eye_title');
const eyeHeader = i18n.t('eye_header');
const eyesArr = [
  i18n.t('eye_array_first_el'),
  i18n.t('eye_array_second_el'),
  i18n.t('eye_array_third_el'),
  i18n.t('eye_array_fourth_el'),
  i18n.t('eye_array_fifth_el'),
];

const eyeBody = eyesArr.map((eyeItem) => {
  return <li>{eyeItem}</li>;
});

const databaseTitle = i18n.t('database_title');
const databaseHeader = i18n.t('database_header');
const databaseArr = [
  i18n.t('database_array_first_el'),
  i18n.t('database_array_second_el'),
  i18n.t('database_array_third_el'),
  i18n.t('database_array_fourth_el'),
];

const databaseBody = databaseArr.map((databaseItem) => {
  return <li>{databaseItem}</li>;
});

const cubesTitle = i18n.t('cubes_title');
const cubesHeader = i18n.t('cubes_header');

const usersTitle = i18n.t('users_title');
const usersHeader = i18n.t('users_header');
const usersText = i18n.t('users_text');

class Expertise extends Component {
  state = {
    toggle: false,
    modalTitle: '',
    modalHeader: '',
    modalText: '',
  };

  openPopUp = (modalTitle, modalHeader, modalText) => {
    this.setState({
      toggle: !this.state.toggle,
      modalTitle: modalTitle,
      modalHeader: modalHeader,
      modalText: modalText,
    });
  };

  closePopUp = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    return (
      <div id="expertises" className="expertises">
        <h2>{this.props.t('expertise')}</h2>
        <h3>{this.props.t('expertise_message')}</h3>
        <table className="center">
          <tbody>
            <tr>
              <ExpertiseItem onOpen={() => this.openPopUp(cloudTitle, cloudHeader, cloudBody)} element={cloud} name={cloudTitle} />
              <ExpertiseItem onOpen={() => this.openPopUp(eyeTitle, eyeHeader, eyeBody)} element={eye} name={eyeTitle} />
              <ExpertiseItem onOpen={() => this.openPopUp(cogsTitle, cogsHeader, cogsBody)} element={cogs} name={cogsTitle} />
            </tr>
            <tr>
              <ExpertiseItem onOpen={() => this.openPopUp(databaseTitle, databaseHeader, databaseBody)} element={database} name={databaseTitle} />
              <ExpertiseItem onOpen={() => this.openPopUp(cubesTitle, cubesHeader)} element={cubes} name={cubesTitle} />
              <ExpertiseItem onOpen={() => this.openPopUp(usersTitle, usersHeader, usersText)} element={users} name={usersTitle} />
            </tr>
          </tbody>
        </table>
        <Dialog onClose={() => this.closePopUp()} aria-labelledby="customized-dialog-title" open={this.state.toggle}>
          <DialogTitle id="customized-dialog-title" className="modalTitleContainner">
            <h4>{this.state.modalTitle}</h4>
            <CloseIcon className="closeButton" onClick={() => this.closePopUp()}>
              {times}
            </CloseIcon>
          </DialogTitle>
          <DialogContent dividers>
            <p className="dialogContent">{this.state.modalHeader}</p>
            <p className="dialogContent">{this.state.modalText}</p>
            <ul>{this.state.modalBody}</ul>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(Expertise);
