import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../Header/Header';
import Carousel from '../../components/Carousel/Carousel';
import Recommendations from '../../components/Recommendations/Recommendations';
import Experience from '../Experience/Experience';
import Expertise from '../Expertise/Expertise';
import About from '../About/About';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet'

import './Home.scss';

class Home extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Expert Sap Developer - EMA Consultancy</title>
        </Helmet>

        <div id="back-to-top-anchor" />
        <Header />
        <Carousel />
        <About />
        <Expertise />
        <Experience />
        <Recommendations/>
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(Home);
