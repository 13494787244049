/**
 * @format
 */

import logo from '../styles/images/logo.png'
import fiori from '../styles/images/fiori.png'
import sap_developer from '../styles/images/sap_dev.jpg'
import cpi_flow from '../styles/images/sap_integration.png'
import profile from '../styles/images/profile.jfif'

const Images = {
  home_logo: logo,
  fiori: fiori,
  sap_developer: sap_developer,
  cpi_flow: cpi_flow,
  profile: profile,
};

export default Images;
