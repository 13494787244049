import React, { Component } from 'react'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { green } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';
import './SuccessModal.scss'

class SuccessModal extends Component {

    hanndleClick = () => {
        let x = false;
        this.props.resetSuccessCase(x)
    }

    render() {
        return (
            <div className='successModalContainer'>
                <div className='header-container-success'>
                    <div className='close-btn-success'>
                        <ClearIcon
                            style={{ fontSize: '20px', color: "#468847" }}
                            onClick={this.hanndleClick}
                        />
                    </div>
                </div>
                <CheckCircleRoundedIcon
                    style={{ color: green[500] }}
                />
                <p>
                    {this.props.t('success_modal')}
                </p>
            </div>
        )
    }
}

export default withTranslation()(SuccessModal)