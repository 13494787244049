import React from 'react';
import { Component } from 'react';
import CopyrightIcon from '@material-ui/icons/Copyright';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Grid from '@material-ui/core/Grid';
import './Footer.scss';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="content">
          <Grid container spacing={0}>
            <Grid container item xs={12} spacing={0} alignItems="center">
              <CopyrightIcon style={{ fontsize: 1, color: 'white', marginRight: '3px' }} />
              <span>EMA Consultancy {new Date().getFullYear()}. All Rights Reserved.</span>
            </Grid>
          </Grid>

          <a target="blank" href="https://www.linkedin.com/in/rgeworkan/" className="linkedInIco">
            <LinkedInIcon style={{ fontsize: 7, color: 'white' }}  />
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
