import { Fragment } from 'react';
import { Component } from 'react'
import NewCarousel from "react-multi-carousel";
import { withTranslation } from 'react-i18next';

import "react-multi-carousel/lib/styles.css";

import "./Recommendations.scss"

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

class Recommendations extends Component {

    render() {
        return (
            <div id="recommendations" className="recommendations">
                <h2>{this.props.t('recommendations')}</h2>
                <h6>{this.props.t('recommendations_message')}</h6>

                <NewCarousel
                    responsive={responsive}
                    className="carousel-element"
                    showDots={true}
                    swipeable={false}
                    draggable={false}
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={5000}
                    partialVisible={false}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
                    deviceType={this.props.deviceType}
                >
                    <Fragment>
                        <div className="recommendation_cont">
                            <div className="recommendation">
                                I have had the pleasure to have Romeo as SAP PO/ ABAP developer on my team at LeaseWeb and in the case I need a SAP PO/PI or ABAP developer in the future, 
                                I would certainly try to get him on the team again. The good thing about Romeo is that he is very versatile and resourceful. 
                                His knowledge and skills does not stop at SAP development, he is a person that really likes a challenge and in the case he touches up a new technology has the ability to learn and adjust quickly. 
                                On top of his technical skills, he has the ability to connect people and lead when needed. Not only in the role as scrum master of the team, but also in project related war room situations, 
                                Romeo takes the lead and brings the team to a higher level while also contributing to the developments itself.
                            </div>
                            <div className="recommendation_giver">
                                <b>Bjorn Tammer</b>
                            </div>
                        </div>
                    </Fragment>
                    <Fragment>
                        <div className="recommendation_cont">
                            <div className="recommendation">
                                In de afgelopen jaren heeft Romeo in mijn SAP projecten uitstekend werk verricht als ontwikkelaar. Ik heb hem leren kennen als iemand die niet alleen op technisch vlak tot heel veel in staat is, 
                                maar ook is hij heel goed in staat om op functioneel niveau mee te denken over de gewenste oplossing. Romeo is iemand die zich erg verantwoordelijk opstelt waardoor je altijd op hem kan terugvallen. 
                                Daarnaast is hij gewoon een hele fijne kerel in de omgang omdat hij met zijn humor zorgt voor een leuke en prettige sfeer binnen het team.
                            </div>
                            <div className="recommendation_giver">
                                <b>Chris de Pater</b>
                            </div>
                        </div>
                    </Fragment>
                    <Fragment>
                        <div className="recommendation_cont">
                            <div className="recommendation">
                                Romeo helped us finish our project in time and in line with the customer expectations. When he joined our team, the project was in some trouble. He provided added value for the team in no time, showing great speed, 
                                analytical and programming skills, enthusiasm and commitment and thus helped us steer the project into safe water. I can highly recommend Romeo as a developing consultant for future projects!
                            </div>
                            <div className="recommendation_giver">
                                <b>Rene Montijn</b>
                            </div>
                        </div>
                    </Fragment>
                </NewCarousel>
            </div>
        )
    }
}

export default withTranslation()(Recommendations);

