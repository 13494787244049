import React, { Component } from 'react';
import ContactSecondPart from '../../components/ContactSecondPart/ContactSecondPart'
import { withTranslation } from 'react-i18next';

import '../../styles/Generic.scss'
import './Contact.scss'

class Contact extends Component {
    render() {
        return (
            <div id="contact" className="container">
                <h2>{this.props.t('contact_title')}</h2>
                <h3>{this.props.t('contact_title_1')}</h3>
                <ContactSecondPart />
            </div>
        );
    }
}

export default withTranslation()(Contact);