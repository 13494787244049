import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';
import { AppBar, Toolbar, List, ListItem, ListItemText, Container, Hidden } from '@material-ui/core';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import BackToTop from '../../components/BackToTop/BackToTop';
import logo from '../../styles/images/ema_full_color.svg';

import '../../styles/Generic.scss';
import './Header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
    };
  }

  scrollToForm(form, type) {
    document.querySelector(form).scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.setState({ type: type });
  }

  render() {
    const { type } = this.state;

    const navLinks = [
      { title: i18n.t('header_about'), path: `#about` },
      { title: i18n.t('header_expertises'), path: `#expertises` },
      { title: i18n.t('header_experience'), path: `#experience` },
      { title: i18n.t('header_recommendations'), path: `#recommendations` },
      { title: i18n.t('header_contact'), path: `#contact` },
    ];

    return (
      <AppBar position="sticky" className="header-app-bar">
        <Toolbar>
          <Container maxWidth="lg" className="header-container">
            <img src={logo} alt="brandIcon" width={155} height={90} onClick={() => this.scrollToForm('#back-to-top-anchor')} />
            <Hidden smDown>
              <List component="nav" aria-labelledby="main navigation" className="header-menu-list">
                <div className={`header-menu-item ${type === 1 ? 'active' : ''}`} onClick={() => this.scrollToForm('#about', 1)}>
                  <ListItem button>
                    <ListItemText primary={i18n.t('header_about')} />
                  </ListItem>
                </div>
                <div className={`header-menu-item ${type === 2 ? 'active' : ''}`} onClick={() => this.scrollToForm('#expertises', 2)}>
                  <ListItem button>
                    <ListItemText primary={i18n.t('header_expertises')} />
                  </ListItem>
                </div>
                <div className={`header-menu-item ${type === 3 ? 'active' : ''}`} onClick={() => this.scrollToForm('#experience', 3)}>
                  <ListItem button>
                    <ListItemText primary={i18n.t('header_experience')} />
                  </ListItem>
                </div>
                <div className={`header-menu-item ${type === 4 ? 'active' : ''}`} onClick={() => this.scrollToForm('#recommendations', 4)}>
                  <ListItem button>
                    <ListItemText primary={i18n.t('header_recommendations')} />
                  </ListItem>
                </div>
                <div className={`header-menu-item ${type === 5 ? 'active' : ''}`} onClick={() => this.scrollToForm('#contact', 5)}>
                  <ListItem button>
                    <ListItemText primary={i18n.t('header_contact')} />
                  </ListItem>
                </div>

                <a href="https://www.linkedin.com/in/rgeworkan/" target="blank" className="header-menu-item">
                  <ListItem button>
                    <ListItemText primary="in" />
                  </ListItem>
                </a>
              </List>
            </Hidden>
            <Hidden mdUp>
              <SideDrawer navLinks={navLinks} />
            </Hidden>
          </Container>
          <BackToTop />
        </Toolbar>
      </AppBar>
    );
  }
}
export default withTranslation()(Header);
