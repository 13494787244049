import React, { Component } from 'react';
import { Fragment } from 'react';
import { Card, Typography } from '@material-ui/core';

import '../../styles/Generic.scss';
import './CardItem.scss';

class CardItem extends Component {
  render() {
    return (
      <Fragment>
        <Card className="root" variant="outlined">
          <Typography className={'MuiTypography--heading'} variant={'h6'} gutterBottom>
            <div className="titleContainer">
              <h3 className="cardTitle">{this.props.title}</h3>
              <h4 className="cardTitle">{this.props.titlePart}</h4>
            </div>
            <div>
              <p className="cardBody">{this.props.content}</p>
              <div className="cardBody"> {this.props.arr}</div>
              <p className="cardBody">{this.props.footer}</p>
            </div>
          </Typography>
        </Card>
      </Fragment>
    );
  }
}

export default CardItem;
