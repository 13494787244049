import React, { Component } from 'react';
import { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';
import CardItem from '../../components/CardItem/CardItem.jsx';

import '../../styles/Generic.scss';
import './Experience.scss';

const firstCardTitle = i18n.t('first_card_title');
const firstTitlePart = i18n.t('first_title_part');

const cardContent = i18n.t('card_content');

const firstCard = [i18n.t('first_card_el_one'), i18n.t('first_card_el_two'), i18n.t('first_card_el_three'), i18n.t('first_card_el_four'), i18n.t('first_card_el_five')];

const firstCardBody = firstCard.map((item, ind) => {
  return (
    <p key={ind} className="cardItem">
      {item}
    </p>
  );
});

const secondCardTitle = i18n.t('second_card_title');
const secondTitlePart = i18n.t('second_title_part');

const secondCard = [i18n.t('second_card_el_one'), i18n.t('second_crad_el_two'), i18n.t('second_card_el_three'), i18n.t('second_card_el_four')];

const secondCardBody = secondCard.map((item, ind) => {
  return (
    <p key={ind} className="cardItem">
      {item}
    </p>
  );
});

const cardFooter = i18n.t('card_footer');

class Experience extends Component {
  render() {
    return (
      <div id="experience" className="experience">
        <Fragment>
          <h2>{this.props.t('experience')}</h2>
          <h3>{this.props.t('experience_message')}</h3>
          <div className="cardContainer">
            <CardItem key={1} title={firstCardTitle} titlePart={firstTitlePart} content={cardContent} arr={firstCardBody} />
            <CardItem key={2} title={secondCardTitle} titlePart={secondTitlePart} content={cardContent} arr={secondCardBody} footer={cardFooter} />
          </div>
        </Fragment>
      </div>
    );
  }
}

export default withTranslation()(Experience);
