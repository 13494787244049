import { Fragment } from 'react';
import { Component } from 'react';
import NewCarousel from 'react-multi-carousel';
import { Hidden } from '@material-ui/core';
import Images from '../../constants/Images';
import { withTranslation } from 'react-i18next';

import 'react-multi-carousel/lib/styles.css';

import '../../styles/Generic.scss';
import './Carousel.scss';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class Carousel extends Component {
  render() {
    return (
      <div>
        <Hidden smDown>
          <NewCarousel
            responsive={responsive}
            className="carousel-element"
            showDots={true}
            swipeable={false}
            draggable={false}
            infinite={true}
            autoPlay={this.props.deviceType !== 'mobile' ? true : false}
            autoPlaySpeed={5000}
            partialVisible={false}
            keyBoardControl={true}
            transitionDuration={500}
            removeArrowOnDeviceType={['tablet', 'mobile']}
            deviceType={this.props.deviceType}
          >
            <Fragment>
              <div className="first-slide">
                <img className="img-cpi-tag" src={Images.cpi_flow} alt="sap integration" />
                <div className="text-of-first-slide">{this.props.t('expert_integratie')}</div>
              </div>
            </Fragment>
            <Fragment>
              <div className="second-slide">
                <img className="img-fiori-tag" src={Images.fiori} alt="sap fiori" />
                <div className="text-of-second-slide">{this.props.t('expert_fiori')}</div>
              </div>
            </Fragment>
            <Fragment>
              <div className="third-slide">
                <img className="img-dev-tag" src={Images.sap_developer} alt="sap developer" />
                <div className="text-of-third-slide">{this.props.t('expert_dev')}</div>
              </div>
            </Fragment>
          </NewCarousel>
        </Hidden>
      </div>
    );
  }
}

export default withTranslation()(Carousel);
