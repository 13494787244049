import React, { Component } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import PlaceIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';

import './ContactInfo.scss';

class ContactInfo extends Component {
  render() {
    return (
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="text-container">{this.props.t('contact_info')}</div>
          </Grid>
          <Grid container item xs={1} spacing={0} alignItems="center">
            <PlaceIcon color="action" />
          </Grid>
          <Grid container item xs={11} spacing={0}>
            <p>Tosca 23, Krimpen aan den IJssel</p>
          </Grid>
          <Grid container item xs={1} spacing={0} alignItems="center">
            <EmailIcon color="action" />
          </Grid>
          <Grid container className="mail" item xs={11} spacing={0}>
            <a href={'mailto: romeogeworkan@emaconsultancy.nl'}>
              <p>romeogeworkan@emaconsultancy.nl</p>
            </a>
          </Grid>
          <Grid container item xs={1} spacing={0} alignItems="center">
            <PhoneIcon color="action" />
          </Grid>
          <Grid container className="tel-number" item xs={11} spacing={0}>
            <a href="tel:+31 (0) 639719576">
              <p>+31 (0) 639719576</p>
            </a>
          </Grid>
          <Grid container item xs={1} spacing={0} alignItems="center">
            <ApartmentIcon color="action" />
          </Grid>
          <Grid container item xs={11} spacing={0}>
            <p>KVK 65102894</p>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation()(ContactInfo);
