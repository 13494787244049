import * as React from 'react';
import { IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { useState } from 'react';

import './SideDrawer.scss';

const SideDrawer = ({ navLinks }) => {
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ [anchor]: open });
  };

  const sideDrawerList = (anchor) => (
    <div className="side-drawer-list" role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List component="nav">
        {navLinks.map(({ title, path }) => (
          <a href={path} key={title} className="link-text">
            <ListItem button>
              <ListItemText primary={title} />
            </ListItem>
          </a>
        ))}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton edge="start" aria-label="menu" onClick={toggleDrawer('right', true)}>
        <Menu fontSize="large" style={{ color: `#3B4251` }} />
      </IconButton>
      <Drawer anchor="right" open={state.right} onOpen={toggleDrawer('right', true)} onClose={toggleDrawer('right', false)}>
        {sideDrawerList('right')}
      </Drawer>
    </React.Fragment>
  );
};

export default SideDrawer;
