import React, { Component } from 'react';

import './ExpertiseItem.scss';

class ExpertiseItem extends Component {
  render() {
    return (
      <td className="expertiseItem" onClick={() => this.props.onOpen()}>
        <div>
          {this.props.element}
          <h3 className="itemTitle">{this.props.name}</h3>
        </div>
      </td>
    );
  }
}

export default ExpertiseItem;
